<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col cols="12">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import HeroImage from '@/components/image/HeroImage';
export default {
	name: 'PageNotFound',
	components: {
		Breadcrumbs,
		HeroImage
	},
	metaInfo: {
		title: '404',
		meta: [
			{
				name: 'description',
				content: 'Sydney Kids Neuropsychology.'
			},
			{
				name: 'robots',
				content: 'noindex'
			}
		]
	},
	mixins: [_MxAppViewClass],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			hero: {
				alt: this.$route.name,
				heading: this.$route.name,
				imgSmall: 'hero-04-small',
				imgLarge: 'hero-04-large'
			}
		};
	}
};
</script>
